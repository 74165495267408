.pharma_head{
    color: #FFF;
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: 19.374px; /* 149.034% */
}

.pharm_desc{
    color: rgba(255, 255, 255, 0.46);
font-size: 8.762px;
font-style: normal;
font-weight: 400;
line-height: 14.467px; /* 165.106% */
}