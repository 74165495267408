@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&family=Roboto&family=Roboto+Serif:opsz,wght@8..144,300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans:wght@600&family=Poppins:wght@300;400;500&family=Roboto&family=Roboto+Serif:opsz,wght@8..144,300&display=swap');



*,
*::after *::before {
  padding: 0;
  margin: 0;
}

body {
  background-color: #efefef;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
.logo {
  font-family: "Montserrat", sans-serif;
}

.btn {
  padding: 15px 30px;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1rem;
}
.btn:focus,
.btn:active {
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn.btn-white {
  background-color: #fff;
  color: #000;
}
.btn.btn-white:hover {
  background-color: #0e49b5;
  color: #fff;
}

.MuiDataGrid-columnHeaders{
  background-color: #f3ebeb;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}
